/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  .footer {
    font-size: 13px;
    .footerWrap {
      .logoImg {
        width: 190px;
      }
      .footerContents {
        padding-left: 20px;
        .footerContentTop {
          display: flex;
          padding: 10px 0;
          ul {
            display: flex;
            gap: 20px;
            li {
            }
          }
          .snsIconsWrap {
            display: none;
          }
        }
        .footerContentBottom {
          padding: 20px 0;
          ul {
            display: flex;
            gap: 20px;
            li {
              margin-bottom: 10px;
            }
          }
        }
        p {
          color: #999999;
          padding-bottom: 10px;
        }
      }
    }
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  .footer {
    font-size: 12px;
    .footerWrap {
      align-items: center;
      .logoImg {
        width: 160px;
      }
      .footerContents {
        .footerContentTop {
          .snsIconsWrap {
            display: none;
          }
        }
        .footerContentBottom {
          ul {
            flex-direction: column;
            gap: 0px;
            li {
              margin-bottom: 2px;
            }
          }
        }
        p {
        }
      }
    }
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  .footer {
    font-size: 12px;
    .footerWrap {
      flex-direction: column-reverse;
      gap: 5px;
      align-items: center;
      .logoImg {
        width: 160px;
        padding-bottom: 5px;
      }
      .footerContents {
        .footerContentTop {
          .snsIconsWrap {
            display: none;
          }
        }
        .footerContentBottom {
          ul {
            flex-direction: column;
            gap: 0px;
            li {
              margin-bottom: 2px;
            }
          }
        }
        p {
        }
      }
    }
  }
}
