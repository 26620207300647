/* main */
#mainHome {
  width: 100%;
  height: 100vh;
  background-image: url(https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainPageImage.png);
  background-position: center center;
  background-size: cover;
  .scrollDownIcon {
    width: 80px;
    transform: translateY(47rem);
    display: none;
  }
  &.mainHomeMobile {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainPageImage_mobile.png");
  }
}

/* main Future */
#mainFuture {
  display: flex;
  align-items: center;
  height: 100vh;
  .mainFutureWrap {
    padding: 10vh 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .futureListBoxWrap {
      display: flex;
      justify-content: center;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      .futureListBox {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:nth-child(1) {
          background: url(https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainFutureBackground_distribution.png);
        }
        &:nth-child(2) {
          background: url(https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainFutureBackground_IT.png);
          background-position: center center;
        }
        &:nth-child(3) {
          background: url(https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainFutureBackground_human.png);
        }
        .futureListInnerBox {
          border: 1px solid #fff;
          width: 90%;
          height: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          h1 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
            padding: 20px;
            color: #fff;
          }
          small {
            font-size: 18px;
            padding: 20px;
            color: #fff;
          }
        }
      }
    }
  }
}

/* mainBusiness */
#mainBusiness {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  .mainBusinessImg {
    width: 50%;
    height: 100vh;
    opacity: 0.5;
    background: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainBusinessBackground.png");
    position: absolute;
    background-size: cover;
    right: 0;
    z-index: -1;
  }
  .mainBusinessText {
    width: 60%;
    padding-top: 4rem;
    ul {
      padding-top: 50px;
      li {
        padding: 20px;
        margin-bottom: 10px;
        .mainBusinessList {
          display: flex;
          align-items: center;
          gap: 20px;
          color: #333333;
          .mainBusinessListIcon {
            img {
              width: 30px !important;
              max-width: none;
            }
          }
          p {
            font-size: 24px;
            color: #333333;
          }
          small {
            font-size: 18px;
          }
        }
      }
      li.active {
        background: #1a4ab8;
        p,
        small {
          color: #fff;
        }
      }
    }
  }
}

#mainAbout {
  text-align: center;
  height: 100vh;
  background: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/mainAboutBackground.png");
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 52px;
}

/* mainNews */
#mainNews {
  .mainNewsBackground {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
    }
    .mainNewsContents {
      .mainNewsTitle {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 2rem 0;
        h1 {
          margin-bottom: 0;
        }
      }
      .moreButton {
        text-align: right;
        font-size: 20px;
        font-weight: bold;
        padding: 0 20px;
      }

      .mainNewsCardWrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 15px;
        .mainNewsCard {
          width: 30%;
          padding: 15px;
          background: #fff;
          box-shadow: 1px 1px 5px rgba(103, 103, 103, 0.064);
          // border: 1px solid #e9e9e9;
          .mainNewsCardImg {
            max-width: 400px;
            aspect-ratio: 7/5;
            overflow: hidden;
            margin: 0 auto 20px;
            text-align: center;
            img {
              height: 100%;
            }
          }
          .mainNewsDate {
            font-size: 20px;
            font-weight: bold;
            margin: 10px 0;
            span {
              color: #1a4ab8;
            }
          }
          .mainNewsCardTitle {
            font-size: 20px;
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
          }
          .mainNewsCardDesc {
            font-size: 18px;
            line-height: 1.3;
            overflow: hidden;
            p {
              text-overflow: ellipsis;
              overflow: hidden;
              line-clamp: 3;
              -webkit-line-clamp: 3;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}
