/* GmarketSans */
@font-face {
  font-family: "GmarketSans";
  font-weight: 100;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/GmarketSansLight.otf");
}
@font-face {
  font-family: "GmarketSans";
  font-weight: 400;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/GmarketSansMedium.otf");
}
@font-face {
  font-family: "GmarketSans";
  font-weight: 700;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/GmarketSansBold.otf");
}

/* Apple Gothic */
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 100;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoT.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 200;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoUL.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 300;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoL.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 400;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoR.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 500;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoM.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 600;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoSB.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 700;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoB.ttf");
}
@font-face {
  font-family: "AppleSDGothic";
  font-weight: 800;
  src: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/fonts/AppleSDGothicNeoEB.ttf");
}
