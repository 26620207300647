.container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
.flex {
  display: flex;
}
.pointer {
  cursor: pointer;
}
.hide {
  display: none;
}
.mainSectionTitle {
  h1 {
    font-size: 52px;
    margin-bottom: 24px;
  }
  p {
    font-size: 30px;
    color: #333333;
    line-height: 42px;
  }
}
/*banner */
.banner {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  background-size: cover;
  background-position: center center;
  .bannerTitle {
    color: #fff;
    font-size: 35px;
  }
  &.introduction {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_introduction.png");
  }
  &.mission {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_mission.png");
  }
  &.worth {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_worth.png");
  }
  &.future {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_future.png");
  }
  &.history {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_history.png");
  }
  &.news {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_news.png");
  }
  &.news-article {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_news.png");
    height: 200px;
  }
  &.contact {
    background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/banner_contact.png");
  }
}
/* introductionContent */
.introductionContTitle {
  text-align: center;
  margin-bottom: 80px;
  h1 {
    font-size: 35px;
  }
  p {
    font-size: 28px;
  }
}

@media screen and (max-width: 1440px) {
  /*banner */
  .banner {
    height: 400px;
    .bannerTitle {
      font-size: 30px;
    }
  }
  .container {
    padding: 0 30px;
  }
  .mainSectionTitle {
    h1 {
      font-size: 40px;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
      line-height: 1.3;
    }
  }

  /* introductionContent */
  .introductionContTitle {
    text-align: center;
    margin-bottom: 80px;
    h1 {
      font-size: 35px;
    }
    p {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 800px) {
  /*banner */
  .banner {
    height: 300px;
    margin-bottom: 60px;
    .bannerTitle {
      font-size: 30px;
    }
  }
  .container {
    padding: 0 20px;
  }
  .mainSectionTitle {
    h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      line-height: 1.3;
    }
  }

  /* introductionContent */
  .introductionContTitle {
    text-align: center;
    margin-bottom: 60px;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 479px) {
  .container {
    padding: 0 10px;
  }
  .mainSectionTitle {
    h1 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      line-height: 1.2;
    }
  }

  /* introductionContent */
  .introductionContTitle {
    margin-bottom: 50px;
    h1 {
      font-size: 26px;
    }
    p {
      font-size: 17px;
    }
  }

  /*banner */
  .banner {
    height: 250px;
    margin-bottom: 40px;
    .bannerTitle {
      font-size: 26px;
    }
  }
}
