/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  #project {
    .project {
      .projectContents {
        .projectNumber {
          font-size: 18px;
        }
        h1 {
          font-size: 38px;
        }
        h2 {
          font-size: 22px;
        }
        p {
          font-size: 17px;
        }
      }
    }
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  #project {
    .project {
      .projectContents {
        .projectNumber {
          font-size: 16px;
        }
        h1 {
          font-size: 36px;
        }
        h2 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
          width: 100%;
        }
      }
    }
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  #project {
    .project {
      .projectContents {
        .projectNumber {
          font-size: 14px;
        }
        h1 {
          font-size: 30px;
        }
        h2 {
          font-size: 18px;
        }
        p {
          font-size: 16px;
          line-height: 1.6;
        }
      }
    }
  }
}
