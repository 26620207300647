.nav {
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
  z-index: 2;
  .navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navBarLink {
      display: flex;
      align-items: center;
      li {
        padding: 0 25px;
        a {
          padding: 20px 0;
        }
        .buttonTag {
          display: block;
        }
        &:hover {
          color: #1a4ab8;
        }
      }
      .recruitButton {
        display: flex;
        align-items: center;
        gap: 4px;
        img {
          width: 16px;
        }
        .careerIcon {
          font-size: 20px;
        }
      }
      .iconBox {
        display: flex;
        gap: 10px;
      }
      .navBarLinkIntroduction {
        opacity: 1;
        position: relative;

        .languageIcon {
          font-size: 22px;
        }
        .dropdownFlex {
          display: flex;
          align-items: center;
        }
        .introductionCategory {
          width: 120px;
          position: absolute;
          left: -4%;
          height: auto;
          top: 100%;
          background: #fff;
          color: #333333;
          text-align: center;
          padding: 10px 0;
          opacity: 1;
          transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
          ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            li {
              padding: 10px;
              &:hover {
                color: #1a4ab8;
              }
            }
          }
          &.korean {
            left: -4%;
          }
          &.rusian {
            left: 6%;
          }
          &.language {
            top: 160%;
            width: 100px;
            left: -15%;
          }
        }
        &.aboutUsClass {
          margin-right: -10px;
        }
      }

      .hideClass {
        position: absolute;
        text-align: center;
        padding: 10px 0;
        visibility: hidden;
        opacity: 0;
        height: 0vh;
      }
    }
  }
  .hamburgerMenu {
    font-size: 24px;
    display: none;
  }
}
/* sideBar */
.blackBackground {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 8;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  .sideBar {
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateX(0);
    position: absolute;
    right: 0;
    max-width: 400px;
    width: 100%;
    height: 100vh;
    background-color: #1a4ab8;
    z-index: 10;
    &.hide {
      transform: translateX(100%);
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .closeButton {
      color: #fff;
      text-align: end;
      font-size: 24px;
      padding: 20px;
    }
    .sideBarContents {
      .sideBarGreeting {
        border-left: 4px solid #a2ff00;
        padding: 2vh;
        font-size: 24px;
        color: #fff;
        margin-left: 2rem;
      }
      .sideBarListWrap {
        background: #fff;
        width: 90%;
        font-size: 24px;
        padding: 2rem;
        border-radius: 0 20px 20px 0;
        margin-top: 2rem;
        ul {
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 2vh;
          li {
            a {
              &:hover {
                color: #1a4ab8;
                transition: all 0.3s;
              }
            }
            ul {
              li {
                font-size: 20px;
                list-style-type: circle;
                margin-left: 20px;
                &:hover {
                  color: #1a4ab8;
                  transition: all 0.3s;
                }
              }
            }
          }
        }
      }
      .languageCheck {
        display: flex;
        flex-flow: wrap;
        gap: 15px;
        justify-content: center;
        color: #fff;
        margin-top: 20px;
      }
    }
  }
  &.hide {
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: -1;
    background: none;
  }
}

.nav.activeNav {
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-bottom: 1px solid #eeeeee;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* main scrollNav */
#mainScrollNav {
  z-index: 9;
  position: fixed;
  bottom: 16rem;
  right: 2rem;
  color: #d9d9d9;
  ul {
    li {
      box-sizing: border-box;
      font-size: 14px;
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 20px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        left: -1px;
        height: 170%;
        border-left: 1px solid #eeeeee;
      }
      .circleBox {
        position: relative;
        span:nth-child(1) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #d9d9d9;
        }
        span:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #d9d9d9;
        }
      }
      .scrollSectionName {
        margin-left: 30px;
      }
    }
  }
  .active {
    color: #2b5da5;
    .circleBox {
      z-index: 3;
      span:nth-child(1) {
        background: #2b5da5;
      }
      span:nth-child(2) {
        border: 1px solid #2b5da5;
      }
    }
  }
}

/* navBar responsive */

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1300px) {
  /* mainScrollNav */
  #mainScrollNav {
    bottom: 8rem;
  }
}

/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  .nav {
    height: 60px;
    .navBar {
      .navBarLink {
        display: none;
      }
      .logoImg {
        width: 190px;
      }
      .hamburgerMenu {
        display: block;
      }
    }
  }

  #mainScrollNav {
    bottom: 4rem;
    ul {
      li {
        font-size: 10px;
        padding-top: 18px;

        .scrollSectionName {
          margin-left: 24px;
        }
      }
    }
  }
}
.dropdownIcon {
  font-size: 24px;
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  #mainScrollNav {
    display: none;
  }
}
/* 모바일 세로 */
@media all and (max-width: 479px) {
  /* navbar */
  .nav {
    height: 50px;
    .navBar {
      .logoImg {
        width: 160px;
      }
    }
  }
  /* sideBar */
  .blackBackground {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 8;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    .sideBar {
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform: translateX(0);
      position: absolute;
      right: 0;
      max-width: 400px;
      width: 100%;
      height: 100vh;
      background-color: #1a4ab8;
      z-index: 10;

      &.hide {
        transform: translateX(100%);
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      .closeButton {
        color: #fff;
        text-align: end;
        font-size: 24px;
        padding: 20px;
      }
      .sideBarContents {
        .sideBarGreeting {
          padding: 10px;
          font-size: 24px;
          margin-left: 3rem;
        }
        .sideBarListWrap {
          font-size: 20px;
          padding: 1rem;
          margin-top: 2rem;
          ul {
            padding: 10px;
            gap: 10px;
            li {
              margin-bottom: 0.5rem;
              ul {
                li {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* 작은 사이즈 모바일 */
@media screen and (max-width: 340px) {
  /* sideBar */
  .blackBackground {
    .sideBar {
      .closeButton {
        font-size: 20px;
        padding: 5px 10px;
      }
      .sideBarContents {
        .sideBarGreeting {
          padding: 10px;
          font-size: 18px;
          margin-left: 1rem;
        }
        .sideBarListWrap {
          font-size: 18px;
          padding: 1rem;
          margin-top: 2rem;
          ul {
            padding: 10px;
            gap: 5px;
            li {
              margin-bottom: 0.5rem;
              ul {
                li {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
