/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  /* introduction */
  .introductionPage {
    .container {
      .aboutMose {
        .aboutMoseImg {
          padding: 20px;
        }
        .aboutMoseDesc {
          width: 100%;
          padding: 0 4rem;
          .aboutMoseTitle {
            font-size: 28px;
          }
          ul {
            padding: 0 1rem;
            li {
              font-size: 18px;
            }
          }
        }
      }
      .moseGrowth {
        h1 {
          font-size: 40px;
        }
        .moseGrowthImg {
          padding: 20px;
        }
      }
    }
  }

  /* introduction Mission */
  .introductionMissionPage {
    .introduction_missionBackground {
      .container {
        .missionQuotationIcon {
          width: 30px;
        }
        h2 {
          font-size: 40px;
        }
        p {
          width: 100%;
          font-size: 17px;
        }
      }
    }
  }

  /* introduction Worth */
  .introductionWorthPage {
    .container {
      .worthContentWrap {
        .worthGridWrap {
          .worthGridItem {
            .worthGridItemDesc {
              .worthGridItemTitle {
                .worthGIcon {
                }
                small {
                  font-size: 20px;
                }
                h2 {
                  font-size: 25px;
                }
              }
              h3 {
                font-size: 23px;
              }
              p {
                padding: 0 2rem;
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
  /* introduction Future */
  .introductionFuturePage {
    .container {
      .futureContentWrap {
        .futureDetailFlexWrap {
          display: flex;
          flex-direction: column;
          width: 100%;
          .futureDetailItem {
            width: 100%;
            padding-top: 100px;
            .futureDetailTitle {
              padding: 20px;
              h2 {
                font-size: 16px;
              }
              p {
                font-size: 16px;
              }
            }
          }
        }
        .futureDescText {
          width: 100%;
          text-align: center;
          padding: 4rem;
          line-height: 1.36;
        }
      }
    }
  }

  /* introduction History */
  .introductionHistoryPage {
    .historyContentWrap {
      .historySection {
        .historyYear {
          font-size: 30px;
          padding-right: 2rem;
        }
        .historyMonthDesc {
          padding-left: 2rem;
          ul {
            li {
              font-size: 18px;
              display: flex;
              &:not(:last-child) {
                margin-bottom: 30px;
              }
              b {
                padding-right: 2rem;
              }
              .historyDescBox {
                .historyMainDescWrapper {
                  font-size: 18px;
                  bottom: 2px;
                  .historyMainDesc {
                    font-size: 23px;
                    position: relative;
                    margin-bottom: 20px;
                    bottom: 0px;
                  }
                }
              }
              p {
              }
            }
          }
        }
      }
    }
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
  /* introduction */
  .introductionPage {
    .container {
      .aboutMose {
        .aboutMoseImg {
          padding: 20px 0;
        }
        .aboutMoseDesc {
          padding: 0;
          .aboutMoseTitle {
            font-size: 20px;
          }
          ul {
            li {
              font-size: 16px;
            }
          }
        }
      }
      .moseGrowth {
        h1 {
          margin: 100px 0;
          font-size: 35px;
        }
        .moseGrowthImg {
        }
      }
    }
  }

  /* introduction Mission */
  .introductionMissionPage {
    .introduction_missionBackground {
      .container {
        .missionQuotationIcon {
          width: 20px;
        }
        h2 {
          font-size: 30px;
        }
        small {
          width: 100%;
          font-size: 16px;
        }
        p {
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }

  /* introduction Worth */
  .introductionWorthPage {
    .container {
      .worthContentWrap {
        .worthGridWrap {
          grid-template-columns: 1fr;
          .worthGridItem {
            &.governance {
              grid-column: inherit;
            }
            .worthGridItemDesc {
              .worthGridItemTitle {
                .worthGIcon {
                }
                small {
                  font-size: 20px;
                }
                h2 {
                  font-size: 25px;
                }
              }
              h3 {
                font-size: 23px;
              }
              p {
                padding: 0 2rem;
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }

  /* introduction History */
  .introductionHistoryPage {
    .historyContentWrap {
      .historySection {
        flex-direction: column;
        gap: 20px;
        padding-left: 20px;
        .historyYear {
          font-size: 25px;
          padding-right: 2rem;
        }
        .historyMonthDesc {
          padding-left: 2rem;
          &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: -11px;
            left: -11px;
            border: 1px solid #1a4ab8;
            background: #fff;
            border-radius: 50%;
          }
          &::after {
            content: "";
            position: absolute;
            top: -5px;
            left: -5px;
            width: 8px;
            height: 8px;
            border: 1px solid #1a4ab8;
            background-color: #1a4ab8;
            border-radius: 50%;
          }
          ul {
            li {
              &:not(:last-child) {
                margin-bottom: 20px;
              }
              b {
                padding-right: 2rem;
              }
              .historyDescBox {
                .historyMainDescWrapper {
                  .historyMainDesc {
                    font-size: 21px;
                  }
                }
                .historyMainDescImg {
                  display: flex;
                  flex-direction: column;
                  padding-top: 10px;
                  gap: 10px;
                  img {
                    width: 90%;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* 모바일 사이즈 */
@media screen and (max-width: 479px) {
  /* introduction */
  .introductionPage {
    .container {
      .aboutMose {
        .aboutMoseImg {
          padding: 0;
        }
        .aboutMoseDesc {
          .aboutMoseTitle {
            font-size: 20px;
          }
          ul {
            li {
              font-size: 15px;
            }
          }
        }
      }
      .moseGrowth {
        h1 {
          margin: 50px 0;
          font-size: 28px;
          padding: 0 40px;
        }
        .moseGrowthImg {
          padding: 0;
        }
      }
    }
  }

  /* introduction History */
  .introductionHistoryPage {
    .historyContentWrap {
      .historySection {
        flex-direction: column;
        gap: 20px;
        padding-left: 10px;
        .historyYear {
          font-size: 22px;
          padding-right: 1rem;
        }
        .historyMonthDesc {
          padding-left: 1.5rem;
          ul {
            li {
              font-size: 16px;
              display: flex;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              b {
                padding-right: 1.5rem;
              }
              .historyDescBox {
                .historyMainDescWrapper {
                  .historyMainDesc {
                    font-size: 20px;
                  }
                }
                .historyMainDescImg {
                  gap: 10px;
                  img {
                    width: 90%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* introduction Mission */
  .introductionMissionPage {
    .introduction_missionBackground {
      .container {
        .missionQuotationIcon {
          width: 18px;
        }
        h2 {
          font-size: 26px;
        }
        small {
          font-size: 14px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }

  /* introduction Worth */
  .introductionWorthPage {
    .container {
      .worthContentWrap {
        .worthGridWrap {
          .worthGridItem {
            &.governance {
            }
            .worthGridItemDesc {
              .worthGridItemTitle {
                .worthIcon {
                  width: 80px;
                }
                small {
                  font-size: 16px;
                }
                h2 {
                  font-size: 22px;
                }
              }
              h3 {
                font-size: 20px;
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  /* introduction Future */
  .introductionFuturePage {
    .container {
      .futureContentWrap {
        .futureDetailFlexWrap {
          .futureDetailItem {
            padding-top: 60px;
            .futureDetailTitle {
              h2 {
                font-size: 14px;
              }
              p {
                font-size: 12px;
              }
            }
          }
        }
        .futureDescText {
          font-size: 15px;
          width: 100%;
          text-align: center;
          padding: 3rem 0;
          line-height: 1.5;
        }
      }
    }
  }
}
