/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  .contactPage {
    .contactContents {
      .contactImageGridWrap {
        .companyAddress {
          ul {
            padding-left: 15px;
            margin: 7.9vw 6vw 1.4vw 2.8vw;
            li {
              p {
                font-size: 18px;
              }
              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 827px) {
  .contactPage {
    .contactContents {
      .contactImageGridWrap {
        .companyAddress {
          ul {
            margin: 5vw 6.9vw 1.4vw 2.8vw;
            li {
              p {
                font-size: 18px;
              }
              span {
                font-size: 12.5px;
              }
            }
          }
        }
      }
    }
  }
}


/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 768px) {
  .contactPage {
    .contactContents {
      .contactImageGridWrap {
        grid-template-columns: 1fr;
        .contact_companyImage {
          grid-column: 1 / 1;
        }
        .companyAddress {
          ul {
            margin: 14vw 6.9vw 1.4vw 2.8vw;
            li {
              p {
                font-size: 18px;
              }
              span {
                font-size: 15px;
              }
            }
          }
        }
      }
      .virtualtourButton {
      }
      .companyFailityGridWrap {
        gap: 12px;
      }
    }
  }
}

/* 모바일 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  .contactPage {
    .contactContents {
      .contactImageGridWrap {
        .companyAddress {
          ul {
            li {
              p {
                font-size: 14px;
              }
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
      .companyFailityGridWrap {
        gap: 4px;
      }
    }
  }
}
