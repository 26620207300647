/* -------------------------------------------------------------labTop */
@media all and (min-width: 1024px) {
  /* main future */
  #mainFuture {
    .mainFutureWrap {
      .futureListBoxWrap {
        .futureListBox {
          height: 300px;
        }
      }
    }
  }
  /* main Business */
  #mainBusiness {
    .mainBusinessImg {
    }
    .mainBusinessText {
      width: 80%;
      ul {
        padding-top: 20px;
        li {
          padding: 15px;
          margin-bottom: 10px;
          .mainBusinessList {
            gap: 20px;
            color: #333333;
            p {
              font-size: 18px;
              color: #333333;
            }
            small {
              font-size: 16px;
            }
          }
        }
        li.active {
          background: #1a4ab8;
          p,
          small {
            color: #fff;
          }
        }
      }
    }
  }
  /* main News */
  #mainNews {
    .mainNewsBackground {
      .mainNewsContents {
        .mainNewsCardWrap {
          .mainNewsCard {
            .mainNewsCardImg {
            }
            .mainNewsCardDescWrap {
              p {
                font-size: 16px;
              }
              b {
                font-size: 18px;
              }
              .mainNewsCardDesc {
                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------------------------------- 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  /* main */
  #mainHome {
    .scrollDownIcon {
      width: 80px;
      transform: translateY(47rem);
      display: none;
    }
  }

  /* main Future */
  #mainFuture {
    .mainFutureWrap {
      .futureListBoxWrap {
        margin-top: 30px;
        .futureListBox {
          height: 250px;
          &:nth-child(1) {
            background-position: 20% 20%;
          }
          &:nth-child(2) {
            background-position: 50% 70%;
          }
          &:nth-child(3) {
            background-position: 0 20%;
          }
          p {
            font-size: 18px;
            padding: 15px;
            color: #fff;
          }
          small {
            font-size: 14px;
            padding: 15px;
            color: #fff;
          }
        }
      }
    }
  }

  /* main Business */
  #mainBusiness {
    .mainBusinessImg {
    }
    .mainBusinessText {
      width: 80%;
      padding-top: 0;
      ul {
        li {
          .mainBusinessList {
            .mainBusinessListIcon {
              img {
                width: 28px !important;
                max-width: none;
              }
            }
            p {
              font-size: 20px;
            }
            small {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  /* main about */
  #mainAbout {
    font-size: 38px;
  }
  /* main News */
  #mainNews {
    .mainNewsBackground {
      .mainNewsContents {
        .mainNewsCardWrap {
          flex-direction: column;
          .mainNewsCard {
            display: flex;
            width: 100%;
            .mainNewsCardFlexWrap {
              display: flex;
              .mainNewsCardImg {
                max-width: 200px;
                width: 100%;
              }
              .mainNewsCardDescWrap {
                margin: 0 10px;
                .mainNewsDate {
                  margin-bottom: 5px;
                }
                .mainNewsCardTitle {
                  font-size: 18px;
                }
                .mainNewsCardDesc {
                  p {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------------------------------- 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 768px) {
  /* main Future */
  #mainFuture {
    .mainFutureWrap {
      .futureListBoxWrap {
        flex-direction: column;
        gap: 20px;
        .futureListBox {
          width: 100%;
          height: 150px;
        }
      }
    }
  }

  /* main Business */
  #mainBusiness {
    .mainBusinessImg {
      display: none;
    }
    .mainBusinessText {
      width: 100%;
      padding-top: 0;
      ul {
        li {
          .mainBusinessList {
            p {
              font-size: 20px;
            }
            small {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  /* main about */
  #mainAbout {
    font-size: 30px;
    word-break: keep-all;
    text-align: center;
    p {
      padding: 30px 80px;
      line-height: 1.5;
    }
  }

  /* main News */
  #mainNews {
    .mainNewsBackground {
      .mainNewsContents {
        .mainNewsCardWrap {
          .mainNewsCard {
            .mainNewsCardImg {
              min-width: 200px;
            }
            .mainNewsCardDescWrap {
              p {
                font-size: 14px;
              }
              b {
                font-size: 16px;
              }
              .mainNewsCardDesc {
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------------------------------- 모바일 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  /* main */

  /* main Future */
  #mainFuture {
    .mainFutureWrap {
      .futureListBoxWrap {
        flex-direction: column;
        gap: 10px;
        .futureListBox {
          width: 100%;
          height: 100px;
          p {
            font-size: 16px;
            padding: 10px;
          }
          small {
            font-size: 12px;
            padding: 10px;
          }
        }
      }
    }
  }

  /* main Business */
  #mainBusiness {
    .mainBusinessText {
      ul {
        padding-top: 20px;
        li {
          padding: 12px;
          .mainBusinessList {
            .mainBusinessListIcon {
              img {
                width: 24px;
              }
            }
            gap: 14px;
            p {
              font-size: 16px;
            }
            small {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  /* main about */
  #mainAbout {
    p {
      padding: 0;
      line-height: 1.2;
    }
  }
  /* main News */
  #mainNews {
    .mainNewsBackground {
      .mainNewsContents {
        .mainNewsTitle {
        }
        .moreButton {
          font-size: 16px;
          padding: 0;
        }
        .mainNewsCardWrap {
          flex-direction: column;
          padding: 0;
          .mainNewsCard {
            display: flex;
            flex-direction: column;
            &:nth-last-child(1),
            &:nth-last-child(2) {
              display: none;
            }
            .mainNewsCardFlexWrap {
              flex-direction: column;
              .mainNewsCardImg {
                max-width: none;
                img {
                  width: 100%;
                  max-height: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
}
