.contactPage {
  h1 {
    margin-bottom: 20px;
  }
  .contactContents {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    .contactImageGridWrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      .contact_companyImage {
        grid-column: 1 / 3;
      }
      .relativeImg {
        position: relative;
      }
      .companyAddress {
        position: absolute;
        top: 0;
        color: #fff;
        ul {
          padding-left: 20px;
          margin: 11vw 6.9vw 1.4vw 2.8vw;
          border-left: 2px solid #fff;
          li {
            display: flex;
            margin-bottom: 10px;
            gap: 10px;
            p {
              font-size: 20px;
              margin-bottom: 5px;
            }
            span {
            }
          }
        }
      }
    }
    .virtualtourButton {
      color: #fff;
      width: 300px;
      text-align: center;
      background-color: #1a4ab8;
      padding: 11px;
    }
    .companyFailityGridWrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}
