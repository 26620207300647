.newsDetailPage {
  article.article-container {
    width: 100%;
    max-width: 1000px;
    border: 1px solid #d1d1d1;
    margin: auto auto 80px;
    padding: 100px;
    .title {
      text-align: center;
      font-size: 22px;
      margin-bottom: 40px;
      word-break: break-all;
    }
    .thumbnail-wrapper {
      max-width: 400px;
      aspect-ratio: 7/5;
      overflow: hidden;
      margin: 0 auto 20px;
      text-align: center;
      img {
        height: 100%;
      }
    }
  }
}
