#project {
  .project {
    width: 100%;
    height: 100vh;
    background-position: center center;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;

    &.seaRoad {
      background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/projectBackground_seaRoad.png");
    }
    &.iraq {
      background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/projectBackground_IRAQ.png");
      text-align: right;
      .projectContents {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    &.domego {
      background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/projectBackground_domego.png");
    }
    &.wish {
      background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/projectBackground_wish.png");
      text-align: right;
      .projectContents {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    .projectContents {
      .projectNumber {
        display: block;
        font-size: 20px;
        margin-bottom: 10px;
      }
      h1 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      h2 {
        font-size: 24px;
        margin-bottom: 35px;
      }
      p {
        font-size: 18px;
        line-height: 2;
        width: 50%;
      }
    }
  }
}
