.introductionPage {
  text-align: center;
  .container {
    padding-bottom: 100px;
    .aboutMose {
      .aboutMoseImg {
        padding: 50px 100px;
      }
      .aboutMoseDesc {
        width: 70%;
        margin: 0 auto;
        .aboutMoseTitle {
          display: block;
          font-size: 35px;
          margin: 20px 0;
        }
        ul {
          li {
            margin-bottom: 30px;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.5;
          }
        }
      }
    }
    .moseGrowth {
      h1 {
        margin: 170px 0;
        font-size: 50px;
        color: #1a4ab8;
        span {
          font-weight: bold;
        }
      }
      .moseGrowthImg {
        padding: 0 100px;
      }
    }
  }
}

/* introduction Mission */
.introduction_missionBackground {
  color: #fff;
  background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/introductionMissionBackground.png");
  background-attachment: fixed;
  padding: 100px 0;
  text-align: center;
  margin-bottom: 100px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
    .missionQuotationIcon {
      width: 40px;
    }
    h2 {
      font-size: 50px;
      span {
        font-weight: normal;
      }
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      width: 70%;
    }
  }
}

/* introduction worth */
.introductionWorthPage {
  text-align: center;
  .container {
    .worthContentWrap {
      padding-bottom: 100px;
      .worthTitle {
        margin-bottom: 100px;
      }
      .worthGridWrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        .worthGridItem {
          color: #fff;
          background-size: cover;
          background-position: center center;
          &.governance {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/introductionWorthGBackground.png");
            grid-column: 1 / 3;
          }
          &.environment {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/introductionWorthEBackground.png");
          }
          &.social {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/introductionWorthSBackground.png");
          }
          .worthGridItemDesc {
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            .worthGridItemTitle {
              display: flex;
              flex-direction: column;
              align-items: center;
              .worthIcon {
                width: 100px;
              }
            }
            small {
              font-size: 22px;
            }
            h2 {
              font-size: 30px;
            }
            h3 {
              font-size: 25px;
            }
            p {
              padding: 0 8rem;
              line-height: 1.5;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

/* introduction Future */
.introductionFuturePage {
  .container {
    .futureContentWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .futureDetailFlexWrap {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        .futureDetailItem {
          color: #fff;
          padding-top: 400px;
          background-position: center center;
          background-size: cover;
          width: 16%;
          &.digital {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/future_digital.png");
          }
          &.computer {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/future_computer.png");
          }
          &.data {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/future_data.png");
          }
          &.ai {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/future_ai.png");
          }
          &.automatic {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/future_automatic.png");
          }
          &.drone {
            background-image: url("https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-web/background/future_drone.png");
          }
          .futureDetailTitle {
            padding: 20px;
            h2 {
              font-size: 16px;
            }
            p {
              display: block;
              font-size: 16px;
            }
          }
        }
      }
      .futureDescText {
        width: 80%;
        text-align: center;
        padding: 4rem 10rem;
        line-height: 1.36;
      }
    }
  }
}

/* introduction History */
.introductionHistoryPage {
  .historyContentWrap {
    .historySection {
      padding-bottom: 100px;
      display: flex;
      .historyYear {
        font-size: 40px;
        padding-right: 4rem;
      }
      .historyMonthDesc {
        border-left: 1px solid #1a4ab8;
        padding-left: 4rem;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          top: -11px;
          left: -12px;
          border: 1px solid #1a4ab8;
          background: #fff;
          border-radius: 50%;
        }
        &::after {
          content: "";
          position: absolute;
          top: -5px;
          left: -6px;
          width: 12px;
          height: 12px;
          border: 1px solid #1a4ab8;
          background-color: #1a4ab8;
          border-radius: 50%;
        }
        ul {
          li {
            line-height: 1.5;
            font-size: 20px;
            display: flex;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            b {
              padding-right: 2rem;
            }
            .historyDescBox {
              // 여러줄의 글자에 걸친 음영(background)을 적용하려면 아래처럼 글자에 wrapper를 씌우면 된다.
              // 단, `display: inline;` 필수.
              // 그런데 `display: inline`인 component에는 width, height 속성이 없다.
              // 그러면 어떻게 디자인 의도대로 음영(background)의 높이랑 위치를 조절할까?
              // 아래처럼 font-size와 bottom을 이용하면 해결 ^.~
              .historyMainDescWrapper {
                display: inline;
                background: rgba(126, 197, 255, 0.3);
                font-size: 23px;
                bottom: 2px; // bottom을 올린만큼, 글자의 bottom은 내려야함. 그래야 정위치.
                position: relative;
                .historyMainDesc {
                  font-weight: 500;
                  font-size: 25px;
                  position: relative;
                  margin-bottom: 20px;
                  bottom: -2px;
                }
              }
              .historyMainDescImg {
                display: flex;
                gap: 10px;
                img {
                  width: 45%;
                  padding: 10px 0;
                }
              }
              p {
                line-height: 1.2;
                margin: calc(20px * 0.5) 0;
                &:first-child {
                  margin: calc(20px * .2) 0 calc(20px * 0.5) 0;
                }
              }
            }
            p {
            }
          }
        }
      }
    }
  }
}
