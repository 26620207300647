.footer {
  width: 100%;
  background: #333333;
  font-size: 14px;
  color: #fff;
  .footerWrap {
    display: flex;
    padding-top: 28px;
    .logoImg {
    }
    .footerContents {
      padding-left: 20px;
      flex: 1;
      .footerContentTop {
        border-bottom: 1px solid #666666;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        ul {
          display: flex;
          gap: 20px;

          li {
          }
        }
      }
      .footerContentBottom {
        padding: 20px 0;
        ul {
          display: flex;
          gap: 20px;
          li {
            margin-bottom: 10px;
          }
        }
      }
      p {
        color: #999999;
        padding-bottom: 10px;
      }
    }
  }
}
