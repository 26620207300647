/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
  .newsPage {
    .newsContents {
      .hotNewsCard {
        padding-bottom: 50px;
        & > a {
          display: flex;
        }
        .hotNewsImage {
          max-width: 350px;
        }
        .hotNewsText {
          small {
            font-size: 18px;
            margin-bottom: 20px;
          }
          h2 {
            font-size: 22px;
            margin-bottom: 10px;
          }
          .hotNewsDescBox {
            width: 100%;
            font-size: 17px;
            line-height: 1.3;
          }
        }
      }
      .newsCardGridWrap {
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 40px;
        grid-template-columns: 1fr 1fr;
        .newsCardGridItem {
          .newsCardImage {
            padding-bottom: 10px;
          }
          h4 {
            font-size: 16px;
            margin-bottom: 5px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 768px) {
  .newsPage {
    .newsContents {
      .hotNewsCard {
        padding-bottom: 50px;
        width: 100%;
        & > a {
          flex-direction: column;
        }
        .hotNewsImage {
          width: unset;
          max-width: none;
          padding: 0;
        }
        .hotNewsText {
          width: 100%;
          small {
            font-size: 16px;
            margin-bottom: 18px;
          }
          h2 {
            font-size: 20px;
          }
          .hotNewsDescBox {
            width: 100%;
            font-size: 16px;
            line-height: 1.3;
            p {
              -webkit-line-clamp: 4;
            }
          }
        }
      }
      .newsFilterCategory {
        margin-bottom: 2rem;
        padding: 10px;
        font-size: 14px;
        ul {
          gap: 15px;
          li {
          }
        }
      }
      .newsCardGridWrap {
        .newsCardGridItem {
        }
      }
    }
  }
}

/* 모바일 세로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  .newsPage {
    .newsContents {
      .hotNewsCard {
        padding-bottom: 30px;
        .hotNewsImage {
        }
        .hotNewsText {
          small {
            font-size: 14px;
            margin-bottom: 10px;
          }
          h2 {
            font-size: 18px;
          }
          .hotNewsDescBox {
            font-size: 14px;
            line-height: 1.3;
            p {
              line-clamp: 3;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      .newsFilterCategory {
        padding: 8px;
        font-size: 13px;
        ul {
          gap: 10px;
        }
      }
      .newsCardGridWrap {
        grid-template-columns: 1fr;
        .newsCardGridItem {
        }
      }
    }
  }
}
