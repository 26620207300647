.newsPage {
  .newsContents {
    padding-bottom: 100px;
    h1 {
      font-size: 40px;
    }
    .hotNewsCard {
      padding-bottom: 50px;
      & > a {
        display: flex;
      }
      .hotNewsImage {
        width: 40%;
        padding-right: 20px;
        margin: auto;
      }
      .hotNewsText {
        width: 60%;
        small {
          display: block;
          font-size: 22px;
          margin-bottom: 26px;
        }
        h2 {
          font-size: 30px;
          margin-bottom: 15px;
        }
        .hotNewsDescBox {
          width: 80%;
          font-size: 18px;
          line-height: 1.3;
          overflow: hidden;
          p {
            text-overflow: ellipsis;
            overflow: hidden;
            line-clamp: 5;
            -webkit-line-clamp: 5;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .newsFilterCategory {
      border-top: 1px solid #cacaca;
      margin-bottom: 4rem;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      ul {
        display: flex;
        gap: 20px;
        li {
          cursor: pointer;
          &.active {
            color: #1A4AB8;
            font-weight: 500;
          }
        }
      }
    }
    .newsCardGridWrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 40px;
      .newsCardGridItem {
        .newsCardImage {
          padding-bottom: 20px;
          aspect-ratio: 7/5;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 12px;
        }
        p {
          font-size: 14px;
        }
      }
      .emptyCategory {
        text-align: center;
      }
    }
  }
}
