@media screen and (max-width: 768px) {
  .newsDetailPage {
    article.article-container {
      padding: 20px;
      margin: auto auto 60px;
      .title h1 {
        font-size: 25px;
        margin-bottom: 40px;
      }
    }
  }
}

@media all and (max-width: 479px) {
  .newsDetailPage {
    article.article-container {
      padding: 20px;
      margin: auto auto 40px;
      .title h1 {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
  }
}
