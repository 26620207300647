.quill-style {
  font-family: "AppleSDGothic";
  font-size: 18px;
  line-height: 1.5;
  p {
    a {
      color: rgb(0, 0, 154) !important;
    }
  }
}
